/* eslint-disable indent */
(function() {
    // trim polyfill : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
    if (!String.prototype.trim) {
        (function() {
            // Make sure we trim BOM and NBSP
            var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
            String.prototype.trim = function() {
                return this.replace(rtrim, '');
            };
        })();
    }

    [].slice.call( document.querySelectorAll( 'input.c-input__field' ) ).forEach( function( inputEl ) {
        // in case the input is already filled..
        if( inputEl.value.trim() !== '' ) {
            inputEl.parentNode.classList.add('c-input--filled'); }
        else { inputEl.parentNode.classList.remove('c-input--filled'); }

        // events:
        inputEl.addEventListener( 'focus', onInputFocus );
        inputEl.addEventListener( 'blur', onInputBlur );

   } );

    function onInputFocus( ev ) {
        ev.target.parentNode.classList.add('c-input--filled');
    }

    function onInputBlur( ev ) {
        if( ev.target.value.trim() === '' ) {
            ev.target.parentNode.classList.remove('c-input--filled');
        }
    }
})();
