/*
  Project: Bcb Front
  Author: LG
 */
const $ = require('jquery');
window.$ = window.jQuery = $;
window.Tether = require('tether');
require('bootstrap');
require('./contact');

const scroller = require('./scroller.js');
scroller.init();
scroller.scrollWindow();
