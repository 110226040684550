'use strict';

const $ = require('jquery');
//window.$ = window.jQuery = $;
require('jquery-scrollify');
//const BASE_PATH = '/dist/assets/images/';

const scroller = {

  init: () => {

    $('[data-scroll]').on('click', (event) => {
      if($(event.currentTarget).hasClass('active')) {
        return false;
      }
      $('.navbar-collapse').collapse('hide');

      let $container = $('[data-id="' + $(event.currentTarget).data('scroll') + '"]');

      if ($container.length) {
        $('.navbar').addClass('hide-top');
        scroller.helpers.scrollTo($container);
      }
      return false;
    });

    // do it better
    $(window).on('activate.bs.scrollspy', () => {
      let index = 0;
      if($('#scrollspy-navbar a.active')[index].hasAttribute('data-transparent')) {
          $('.navbar-logomato').addClass('is-transparent');
      } else {
          $('.navbar-logomato').removeClass('is-transparent');
      }
    });
    //section scroll

    $.scrollify({
      section : ".c-section",
      sectionName : 'section-name',
      //interstitialSection: "",
      // interstitialSection : "",
      // interstitialSection: "footer",
      easing: "easeOutExpo",
      scrollSpeed: 400,
      offset : 0,
      setHeights: false,
      //touchScroll: true,
      // scrollbars: true,
      // standardScrollElements: "",
      // updateHash: true,
      // touchScroll:true,
      before:function() {
        $('.navbar').addClass('hide-top');
      },
      after:function() {
        $('.navbar').removeClass('hide-top');
      },
      afterResize:function() {
          var mobileViewport = window.matchMedia("screen and (max-width: 992px)");
          mobileViewport.addListener(function(mq) {
              if(mq.matches) {
                $.scrollify.disable();
              } else {
                $.scrollify.enable();
              }
          });
      },
       afterRender:function() {
         // console.log('after');
       }
    });

    var mobileViewport = window.matchMedia("screen and (max-width: 992px)");
    if(mobileViewport.matches) {
        $.scrollify.disable();
    } else {
        $.scrollify.enable();
    }
  },

  helpers: ( () => {
    const scrollTo = ($toContainer, callback) => {
      const itemPrefix = 0;
      const offsetTop = 0;

      if ($toContainer instanceof $) {

        $('body, html').animate({scrollTop: $toContainer[itemPrefix].offsetTop - offsetTop}, 500, () => {
          if (callback != undefined) {
            callback();
          }
        });
      } else {
        $('body, html').animate({scrollTop: '#top'}, 'slow');
      }
    };
    return {
      scrollTo: scrollTo
    };
  })(),

  scrollWindow: () => {
    // if (!$('#map').length) {
    //   return;
    // }

    $(window).on('scroll.sidebar', function () {

     // console.log(this);

      // var topScroll = $(this).scrollTop();
      // var diverse = $(window).height() / 2;

      // if ($('#about').length) {
      //   if (topScroll < $(window).height()) {
      //     $('body').removeClass('inverse');
      //   }
      //
      //   //@todo more reusable
      //
      //   if (topScroll >= ($('#about').position().top - diverse)) {
      //     $('body').addClass('inverse');
      //   }
      //
      //   if (topScroll >= $('#work').position().top - diverse && $(window).width() > 640) {
      //     $('body').removeClass('inverse');
      //   }
      //
      //   if (topScroll >= $('#products').position().top - diverse) {
      //     $('body').addClass('inverse');
      //   }
      //
      //   if (topScroll >= $('#map').position().top - diverse) {
      //     $('body').removeClass('inverse');
      //   }
      // }
    });
  },
  // hashScroll: function() {
  //   var _this = this;
  //
  //   if(window.location.hash.length > 0) {
  //     var name = window.location.hash.replace('#', ''),
  //       $container = $('[data-id=' + name + ']');
  //
  //     if($container.length > 0) {
  //
  //       setTimeout(function() {
  //         _this.helpers.scrollTo($container);
  //       }, 300)
  //     }
  //
  //     try {
  //       history.replaceState('', document.title, window.location.pathname);
  //     } catch(e) {
  //       //console.log(e);
  //     }
  //   }
  // },
};

module.exports = scroller;

